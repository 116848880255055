import * as yup from 'yup'
import { parse, isValid, isAfter } from 'date-fns'
import i18n from 'i18n/i18n'

const validateTimeAfterNow = (value: string | undefined, dateValue: string | undefined) => {
  if (!value || !dateValue) return true

  const selectedDate = parse(dateValue, 'yyyy-MM-dd', new Date())
  
  const [hours, minutes] = value.split(':').map(Number)
  
  selectedDate.setHours(hours, minutes, 0, 0)

  const now = new Date()

  return isAfter(selectedDate, now) || selectedDate.getTime() === now.getTime()
}

export const EvaluationValidation = yup.object().shape({
  date: yup
    .string()
    .required(i18n.t('errors:requiredField'))
    .test('valid-date', i18n.t('errors:fields.date.invalid'), (value) => {
      return value ? isValid(parse(value, 'yyyy-MM-dd', new Date())) : false
    }),

  hour: yup
    .string()
    .required(i18n.t('errors:requiredField'))
    .matches(/^\d{2}:\d{2}$/, i18n.t('errors:fields.hour.invalidFormat'))
    .test('time-after-now', i18n.t('errors:fields.hour.timeAfterNow'), function (value) {
      return validateTimeAfterNow(value, this.parent.date)
    }),
})
