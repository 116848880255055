import { useEffect } from 'react'
import { useQueryClient } from 'react-query'

import useSocket from 'config/socket/useSocket'

import { useEvaluationStore } from 'modules/map/store/evaluation-store'

export const EvaluationSocketContainer = ({ pointId }: { pointId: string }) => {
	const anonymousChannel = '/point/essay-point/incognito-mode'
	const individualChannel = '/point/essay-point/individual-mode'
	const multipleResponseChannel = '/point/essay-point/multiple-response-mode'
	const userEvaluationChannel = '/point/essay-point/user-evaluation-mode'
	const endTimeModeChannel = '/point/essay-point/end-time-mode'

	const queryClient = useQueryClient()
	const { subscribe: anonymousSubscribe } = useSocket(anonymousChannel)
	const { subscribe: individualSubscribe } = useSocket(individualChannel)
	const { subscribe: multipleResponseSubscribe } = useSocket(
		multipleResponseChannel
	)
	const { subscribe: userEvaluationSubscribe } = useSocket(
		userEvaluationChannel
	)
	const { subscribe: endTimeModeSubscribe } = useSocket(
		endTimeModeChannel
	)
	const setIsAnonymous = useEvaluationStore((state) => state.setIsAnonymous)
	const setIsIndividual = useEvaluationStore((state) => state.setIsIndividual)
	const setIsMultipleResponse = useEvaluationStore(
		(state) => state.setIsMultipleResponse
	)
	const setIsUserEvaluation = useEvaluationStore(
		(state) => state.setIsUserEvaluation
	)
	const setIsEndTimeMode = useEvaluationStore(
		(state) => state.setEndTimeMode
	)
	const setClosingDate = useEvaluationStore(
		(state) => state.setClosingDate
	)

	const updateCacheData = ({
		isAnonym,
		isIndividual,
		isMultipleResponse,
		iseUserEvaluation,
		isEndTimeMode,
		closingDate
	}: {
		isAnonym?: boolean
		isIndividual?: boolean
		isMultipleResponse?: boolean
		iseUserEvaluation?: boolean
		isEndTimeMode?: boolean
		closingDate?: string
	}) => {
		const query = queryClient.getQueryCache().find(['essayPoint', pointId])
		if (query && isAnonym) {
			query.setData((point: any) => {
				point.incognito_mode = isAnonym
				return point
			})
		}
		if (query && isIndividual) {
			query.setData((point: any) => {
				point.individual_mode = isIndividual
				return point
			})
		}
		if (query && isMultipleResponse) {
			query.setData((point: any) => {
				point.multiple_response_mode = isMultipleResponse
				return point
			})
		} if (query && iseUserEvaluation) {
			query.setData((point: any) => {
				point.user_evaluation_mode = iseUserEvaluation
				return point
			})
		}
		else if (query && isEndTimeMode && closingDate) {
			query.setData((point: any) => {
				point.end_time_mode = isEndTimeMode
				point.closing_date = closingDate
				return point
			})
		}
	}

	useEffect(() => {
		anonymousSubscribe<{ id: string; incognito_mode: boolean }>(
			'EDIT',
			(data) => {
				if (data.id === pointId) {
					setIsAnonymous(data.incognito_mode)
					updateCacheData({ isAnonym: data.incognito_mode })
				}
			}
		)

		individualSubscribe<{ id: string; individual_mode: boolean }>(
			'EDIT',
			(data) => {
				if (data.id === pointId) {
					setIsIndividual(data.individual_mode)
					updateCacheData({ isIndividual: data.individual_mode })
				}
			}
		)

		multipleResponseSubscribe<{ id: string; multiple_response_mode: boolean }>(
			'EDIT',
			(data) => {
				if (data.id === pointId) {
					setIsMultipleResponse(data.multiple_response_mode)
					updateCacheData({ isMultipleResponse: data.multiple_response_mode })
				}
			}
		)

		userEvaluationSubscribe<{ id: string; user_evaluation_mode: boolean }>(
			'EDIT',
			(data) => {
				if (data.id === pointId) {
					setIsUserEvaluation(data.user_evaluation_mode)
					updateCacheData({ iseUserEvaluation: data.user_evaluation_mode })
				}
			}
		)

		endTimeModeSubscribe<{ id: string; end_time_mode: boolean; closing_date: string }>(
			'EDIT',
			(data) => {
				if (data.id === pointId) {
					setIsEndTimeMode(data.end_time_mode)
					setClosingDate(data.closing_date)
					updateCacheData({ isEndTimeMode: data.end_time_mode, closingDate: data.closing_date })
				}
			}
		)
	}, [pointId])

	return null
}
