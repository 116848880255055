import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Center, Spinner, useToast } from '@chakra-ui/react'

import { updateToken } from 'config/fetch'
import { sessionManager } from 'config/session-manager'

import { useQueryParams } from 'modules/common/hooks'
import { verifyEmail } from 'modules/user/usecases/verify-email'

export const EmailValidation = () => {
	const { t } = useTranslation()
	const query = useQueryParams()
	const email = query.get('email') as string
	const code = query.get('code') as string
	const toast = useToast()
	const navigate = useNavigate()

	const verificationChannel = new BroadcastChannel('email_verification')

	const validateEmail = async () => {
		try {
			const { verified } = await verifyEmail({ email, code })
			if (verified) {
				verificationChannel.postMessage({ verified: true })
				navigate('/dashboard')
				toast({
					title: t('common:emailConfirmation.validatedMessageFromSignin'),
					status: 'success'
				})
			} else {
				sessionManager.endSession()
				navigate('/signin')
				toast({
					title: t('common:emailConfirmation.invalidatedMessage'),
					description: t('common:emailConfirmation.invalidatedMessageCode'),
					status: 'error'
				})
			}
			await updateToken()
		} catch (error) {
			sessionManager.endSession()
			navigate('/signin')
			console.log('error on validateEmail', error)
		}
	}
	useEffect(() => {
		validateEmail()

		return () => {
			verificationChannel.close()
		}
	}, [])

	return (
		<Center maxH='100vh' h='100%'>
			<Spinner size='xl' color='custom_scheme_color_button' />
		</Center>
	)
}
