import { useMutation } from 'react-query'

import useSocket from 'config/socket/useSocket'
import { ConclusionPointParams } from '../types/index'
import { AxiosResponse } from 'axios'
import { useEvaluationStore } from 'modules/map/store/evaluation-store'

import { updateConclusionPoint } from '../usecases/manage-end-time-mode'

export function useEndTimeModeMutation() {

	return useMutation<AxiosResponse<ConclusionPointParams>, unknown, ConclusionPointParams>(
		async (data: ConclusionPointParams) => {
			return await updateConclusionPoint(data)
		}
	)

}
