import React, { ReactNode } from 'react'

import create from 'zustand'
import createContext from 'zustand/context'

type EvaluationStore = {
	isAnonymous: boolean
	isIndividual: boolean
	hasAnswers: boolean
	isMultipleResponse: boolean
	isUserEvaluation: boolean
	endTimeMode: boolean
  	closingDate: string
	status: string
	setIsIndividual: (isIndividual: boolean) => void
	setIsAnonymous: (isAnonymous: boolean) => void
	setIsMultipleResponse: (isMultipleResponse: boolean) => void
	setIsUserEvaluation: (isUserEvaluation: boolean) => void
	setHasAnswers: (isAnonymous: boolean) => void
	setEndTimeMode: (endTimeMode: boolean) => void
	setClosingDate: (closingDate: string) => void
}

const { Provider, useStore } = createContext<EvaluationStore>()

type EvaluationProviderProps = {
	children: ReactNode
	value: {
		isAnonymous: boolean
		isIndividual: boolean
		hasAnswers: boolean
		isMultipleResponse: boolean
		isUserEvaluation: boolean
		endTimeMode: boolean
  		closingDate: string
		status: string
	}
}

function EvaluationProvider({ children, value }: EvaluationProviderProps) {
	const createStore = () =>
		create<EvaluationStore>((set) => ({
			setIsAnonymous: (isAnonymous: boolean) => set({ isAnonymous }),
			setIsIndividual: (isIndividual: boolean) => set({ isIndividual }),
			setIsMultipleResponse: (isMultipleResponse: boolean) =>
				set({ isMultipleResponse }),
			setIsUserEvaluation: (isUserEvaluation: boolean) =>
				set({ isUserEvaluation }),
			setHasAnswers: (hasAnswers: boolean) => set({ hasAnswers }),
			setEndTimeMode: (endTimeMode: boolean) => set ({ endTimeMode }),
			setClosingDate: (closingDate: string) => set({ closingDate }),
			...value
		}))

	return <Provider createStore={createStore}>{children}</Provider>
}

export { useStore as useEvaluationStore, EvaluationProvider }
