import { useTranslation } from 'react-i18next'
import { Button, Flex, Image, Tooltip, useBreakpointValue, useDisclosure, useToast } from '@chakra-ui/react'
import Plus from 'modules/map/components/chat/plus.svg'
import { useEvaluationStore } from 'modules/map/store/evaluation-store'
import AnswerPoint from '../answer-point'
import { getUserResponsesCount } from '../usecases/get-user-responses-count'
import { ModalDeleteAllAnswers } from './modal-delete-all-user-answers'
import { useState, useEffect } from 'react'
import { compareDesc, parseISO } from 'date-fns'

type Props = {
  topic: string
  pointId: string
  journeyId: string
}

export const CreateAnswerButton = ({ topic, pointId, journeyId }: Props) => {
  const { t } = useTranslation()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const answerDisclosure = useDisclosure()
  const deleteDisclosure = useDisclosure()
  const { isMultipleResponse, closingDate, endTimeMode } = useEvaluationStore()
  const toast = useToast()

  const [isButtonVisible, setIsButtonVisible] = useState(true)

  const checkTimeLimit = () => {
    const currentTime = new Date()
    const closingDateParsed = parseISO(`${closingDate}Z`)

    currentTime.setSeconds(0, 0)
    closingDateParsed.setSeconds(0, 0)

    if (currentTime > closingDateParsed) {
      setIsButtonVisible(false)
    } else {
      setIsButtonVisible(true)
    }
  }

  const openAnswerModal = async () => {
    try {
      checkTimeLimit()

      const count = await getUserResponsesCount(pointId)
      if (!isMultipleResponse && count > 0) {
        deleteDisclosure.onOpen()
        return
      }
      answerDisclosure.onOpen()
    } catch (error) {
      toast({
        title: t('errors:default'),
        status: 'error',
        isClosable: true,
        position: 'bottom'
      })
    }
  }

  useEffect(() => {
    if (endTimeMode) {
      checkTimeLimit()

      const intervalId = setInterval(checkTimeLimit, 4000)

      return () => clearInterval(intervalId)
    } else {
      setIsButtonVisible(false)
    }
  }, [closingDate, endTimeMode])

  return (
    <>
      {!isButtonVisible || !endTimeMode ? (
        <Flex display='none' />
      ) : (
        <>
          <Tooltip placement='left' label={t('divergencePoint:chat:add_comment')}>
            <Button
              onClick={openAnswerModal}
              position={isMobile ? 'fixed' : 'absolute'}
              bottom='6'
              right='6'
              borderRadius='50%'
              maxWidth='3rem'
              w='3rem'
              h='3rem'
              maxHeight='3rem'
              backgroundColor='evaluation_create_answer_button'
              _hover={{ backgroundColor: 'none' }}
            >
              <Image src={Plus} />
            </Button>
          </Tooltip>
          <AnswerPoint {...answerDisclosure} topic={topic} pointId={pointId} />
          <ModalDeleteAllAnswers
            {...deleteDisclosure}
            journeyId={journeyId}
            pointId={pointId}
          />
        </>
      )}
    </>
  )
}
