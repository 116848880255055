import { fetch } from 'config'
import { ConclusionPointParams } from '../types/index'


export async function updateClosingDate({
	closingDate,
    endTimeMode,
	pointId
}: ConclusionPointParams) {

	return await fetch<ConclusionPointParams>({
		url: `/projects/v1/essay-point/${pointId}/closing-date`,
		method: 'PATCH',
        data: !endTimeMode ? undefined : { closing_date: closingDate }

	})
}
