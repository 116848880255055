import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiDownload } from 'react-icons/bi'
import { useParams } from 'react-router-dom'

import {
	Badge,
	Box,
	Button,
	Flex,
	HStack,
	Icon,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	Stack,
	Text,
	VStack,
	useBreakpointValue,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import SelectColor from 'modules/common/components/hexagon-icon'
import { IconStEvaluation } from 'modules/common/components/icons'
import Loading from 'modules/common/components/loading'
import { PrimaryCreditsBtn } from 'modules/common/components/use-credits-button/primary-button'
import { DOWNLOAD_CSV } from 'modules/constants'
import { useJourney } from 'modules/journeys/hooks'
import { EssayCreateResponse } from 'modules/map/modules/evaluation-point/types'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { Point } from 'modules/map/modules/map-canvas/types'
import { evaluationPointChannel } from 'modules/map/modules/point/evaluation_point_channel'
import {
	EvaluationProvider,
	useEvaluationStore
} from 'modules/map/store/evaluation-store'
import useEssaysStore from 'modules/map/store/use-essays-store'
import { EvaluationResponse } from 'modules/map/types'
import { Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'
import { consumeCredits } from 'modules/user/usecases/consume-credits'
import { updateUserCache } from 'modules/user/utils/update-user-cache'
import { compareAsc } from 'date-fns'


import { CreateAnswerButton } from './components/create-answer-button'
import { EssayComponent } from './components/essay-answer-component'
import EvaluationSettings from './components/evaluation-point-settings'
import { EvaluationSocketContainer } from './components/evaluation-socket-container'
import { ModalEvaluateAll } from './components/modal-evaluate-all-answers/index'
import { MotivationText } from './components/motivation-text'
import { PointBar } from './components/point-bar'
import { useEssayPoint } from './hooks/use-essay-point'
import { csvmakerEvaluationPoint, downloadXLSX } from './utils'
import { date } from 'yup'
import Language from 'modules/user/components/language'
import { ConclusionBar } from './components/conclusion-bar'

export const ContentEvaluationPoint = ({ pointId }: { pointId: string }) => {
	const { id: journeyId = '' } = useParams<{ id: string | undefined }>()
	const { data: pointContent, isSuccess, isLoading } = useEssayPoint(pointId)
	const selectedPoint = useMapStore(({ state }) => state.selectedPoint)
	const { t, i18n: { language } } = useTranslation()

	if (isLoading || !isSuccess) {
		return <Loading />
	}
	
	return (
		<EvaluationProvider
			value={{
				isAnonymous: pointContent.incognito_mode,
				isIndividual: pointContent.individual_mode,
				isMultipleResponse: pointContent.multiple_response_mode,
				isUserEvaluation: pointContent.user_evaluation_mode,
				hasAnswers: pointContent.has_answers,
				endTimeMode: pointContent.end_time_mode,
  				closingDate: pointContent.closing_date,
				status: pointContent.status
				
			}}
		>
			{selectedPoint && (
				<EvaluationSocketContainer pointId={selectedPoint.id} />
			)}
			<EvaluationModalContent
				journeyId={journeyId}
				pointId={pointId}
				pointContent={pointContent}
				selectedPoint={selectedPoint}
				isSuccess={isSuccess}
			/>
		</EvaluationProvider>
	)
}

type Props = {
	journeyId: string
	pointId: string
	pointContent: EssayCreateResponse
	selectedPoint: Point | null
	isSuccess: boolean
}

const EvaluationModalContent = ({
	journeyId,
	pointId,
	pointContent,
	selectedPoint,
	isSuccess
}: Props) => {
	const { t } = useTranslation()
	const toast = useToast()
	const disclosure = useDisclosure()
	const { data: users = [] } = useJourney(journeyId, {
		select: (journey) => journey.users
	})
	const { user: currentUser } = useMe()
	const hasPrivileges = !!users
		.filter((user) => user.id === currentUser?.id)
		.find(({ project_roles }) => Role(project_roles).can('divergence.delete'))
	const { essayPointResponses } = useEssaysStore()
	const { isAnonymous, status, hasAnswers, setHasAnswers, isIndividual, setClosingDate } =
		useEvaluationStore()
	const [showSupportTexts, setSowSupportTexts] = useState(!hasAnswers)
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const buttonText = showSupportTexts
		? t('buttons:seeLess')
		: t('buttons:seeMore')
	const bgPointColor = !hasAnswers
		? 'blue.50'
		: isAnonymous
			? 'gray.200'
			: 'blue.50'
	const [isLoadingXLSX, setIsLoadingXLSX] = useState(false)
	const [isClosingDatePassed, setIsClosingDatePassed] = useState(false)
	const isClosedPoint = status === 'CLOSED'

	const responsesAmount = essayPointResponses.reduce((acc, essayPoint) => {
		const hasBeenEvaluated =
			essayPoint.evaluation_result?.base_grade >= 0 &&
			essayPoint.evaluation_result?.result?.length

		if (!hasBeenEvaluated) {
			acc += 1
		}
		return acc
	}, 0)

	const hasContentToEvaluate = hasAnswers && responsesAmount

	const {
		fetchEssaysAndReplies,
		addEssayState,
		updateEssayState,
		deleteEssayState
	} = useEssaysStore()
	const { subscribe } = useSocket(
		evaluationPointChannel({ projectId: journeyId })
	)

	useEffect(() => {
		if (selectedPoint) {
			subscribe<EvaluationResponse>('ADD', (data) => {
				if (
					data.essay_point_id === selectedPoint.id &&
					(!isIndividual || hasPrivileges)
				) {
					if (!hasAnswers) {
						setHasAnswers(true)
					}
					const responseWithComments = {
						...data,
						responses: []
					} as EvaluationResponse
					addEssayState(responseWithComments)
				}
			})
			subscribe<EvaluationResponse>('EDIT', (data) => {
				if (data.essay_point_id === selectedPoint.id) {
					updateEssayState(data)
				}
			})
			subscribe<EvaluationResponse>('DELETE', (data) => {
				if (data.essay_point_id === selectedPoint.id) {
					if (essayPointResponses.length === 1 && !data.parent_id) {
						setHasAnswers(false)
					}
					deleteEssayState(data)
				}
			})
			subscribe<EvaluationResponse>('ADD_AGREEMENT', (data) => {
				if (data.essay_point_id === selectedPoint.id) {
					updateEssayState(data)
				}
			})
			subscribe<EvaluationResponse>('DELETE_AGREEMENT', (data) => {
				if (data.essay_point_id === selectedPoint.id) {
					updateEssayState(data)
				}
			})
		}
	}, [
		addEssayState,
		updateEssayState,
		deleteEssayState,
		journeyId,
		selectedPoint,
		subscribe
	])

	useEffect(() => {
		if (isSuccess) {
			fetchEssaysAndReplies(pointId)
		}
	}, [fetchEssaysAndReplies, isSuccess, isIndividual])

	useEffect(() => {
		if (pointContent.closing_date){
			setClosingDate(pointContent.closing_date)
		}
	},[pointContent])

	useEffect(() => {
		setSowSupportTexts(!hasAnswers)
	}, [hasAnswers])

	const updateUserCredits = (newValue: number) => {
		updateUserCache({ credits: newValue })
	}

	const handleDownloadSheets = async () => {
		if (!hasAnswers) {
			toast({
				title: t('evaluationPoint:view.noAnswer'),
				description: t('evaluationPoint:view.noAnswerDescription'),
				status: 'info',
				duration: 3000,
				isClosable: true,
				position: 'bottom'
			})
			return
		}

		setIsLoadingXLSX(true)

		const users = essayPointResponses.map((response) => response.author.name)
		const evaluation = essayPointResponses.map((response) =>
			response.evaluation_result && response.evaluation_result.result
				? response.evaluation_result.result
				: ''
		)
		const grade = essayPointResponses.map((response) =>
			response.evaluation_result && response.evaluation_result.base_grade
				? response.evaluation_result.base_grade
				: 0
		)
		const answer = essayPointResponses.map((response) => response.text)

		const data = {
			name: users,
			grade,
			answer,
			evaluation
		}

		const csvdata = csvmakerEvaluationPoint(data)

		await downloadXLSX(csvdata)
		setIsLoadingXLSX(false)

		toast({
			title: t('evaluationPoint:view.sucessDownlaod'),
			status: 'success',
			duration: 3000,
			isClosable: true,
			position: 'bottom'
		})

		consumeCredits(DOWNLOAD_CSV)
		updateUserCredits((currentUser?.credits || 0) - DOWNLOAD_CSV)
	}

	const handleOpenEvaluateAll = () => {
		disclosure.onOpen()
	}

	useEffect(() => {
		const interval = setInterval(() => {
		  if (pointContent.closing_date) {
			const currentDate = new Date()
			const closingDate = new Date(`${pointContent.closing_date}Z`)
	  
			currentDate.setSeconds(0, 0)
			closingDate.setSeconds(0, 0)
	  
			if (compareAsc(currentDate, closingDate) === 1) {
			  setIsClosingDatePassed(true)
			} else {
			  setIsClosingDatePassed(false)
			}
		  }
		}, 4000)
	  
		return () => clearInterval(interval)
	  }, [pointContent.closing_date])  
	
	return (
		<ModalContent
			bg={bgPointColor}
			height='90vh'
			display='flex'
			flexDirection='column'
		>
			<ModalCloseButton
				mt='19px'
				mr={2}
				color='gray.400'
				marginTop={selectedPoint?.visible ? ['15px', '18px'] : ['23px', '19px']}
			/>
			<ModalHeader borderTopRadius='2xl' bgColor='white' py={4} px={7}>
				<HStack mr={8} justify='space-between'>
					<Stack direction='row' alignItems='center'>
						<SelectColor
							color='orange.700'
							visible={selectedPoint?.visible}
							isEvaluation
							mb={[4, 2]}
							Icon={IconStEvaluation}
						/>
						<Box>
							<Text
								fontWeight='semibold'
								fontSize={['lg', 'xl']}
								mt={selectedPoint?.visible ? 0 : ['-20px', 0]}
							>
								{t('evaluationPoint:title')}
							</Text>
							{!isMobile && (
								<Text color='gray.400' fontWeight='medium' fontSize='md'>
									{t('evaluationPoint:description')}
								</Text>
							)}
						</Box>
					</Stack>
					{hasPrivileges && currentUser && (
						<HStack>
							{!isMobile && (
								<PrimaryCreditsBtn
									icon={<BiDownload />}
									onClick={handleDownloadSheets}
									price={DOWNLOAD_CSV}
									text={t('evaluationPoint:view.download')}
									userCredits={currentUser.credits}
									isLoading={isLoadingXLSX}
									buttonProps={{ mb: selectedPoint?.visible ? 0 : 4 }}
								/>
							)}

							{hasContentToEvaluate && !isMobile && (
								<Button
									ml='-45px'
									mr={12}
									mb={selectedPoint?.visible ? 0 : 4}
									rightIcon={
										<Badge fontSize={12} variant='solid' colorScheme='red'>
											beta
										</Badge>
									}
									onClick={handleOpenEvaluateAll}
									colorScheme='green'
								>
									{t('buttons:evaluateAll')}
								</Button>
							)}
							<EvaluationSettings
								essayPoint={pointContent}
								onDownloadSheets={handleDownloadSheets}
								userCredits={currentUser?.credits}
							/>
							{disclosure.isOpen && (
								<ModalEvaluateAll
									user={currentUser}
									pointId={pointId}
									responsesAmount={responsesAmount}
									{...disclosure}
								/>
							)}
						</HStack>
					)}
				</HStack>
				{isMobile && (
					<Button
						mt={4}
						rightIcon={
							<Badge fontSize={12} variant='solid' colorScheme='red'>
								beta
							</Badge>
						}
						onClick={handleOpenEvaluateAll}
						colorScheme='green'
					>
						{t('buttons:evaluateAll')}
					</Button>
				)}
			</ModalHeader>
			<ModalBody px='0' pt='0' overflowY='auto' borderBottomRadius='2xl'>
				<Box
					maxHeight={'fit-content'}
					bg='white'
					flexGrow={1}
					px={7}
					pb={2}
				>
					<VStack
						maxHeight={showSupportTexts ? 'fit-content' : '20vh'}
						overflow='hidden'
						alignItems='flex-start'
						minHeight={hasAnswers && showSupportTexts ? 'fit-content' : '30vh'}
					>
						<Text color='gray.300' fontSize='sm'>
							{t('evaluationPoint:view.theme')}
						</Text>
						<Text color='gray.400' fontSize={['md', 'lg']} fontWeight='bold'>
							{pointContent.theme}
						</Text>
						<Box mt={2} pb={6}>
							{pointContent?.support_texts.map((text, index) => (
								<MotivationText key={index} content={text} count={index + 1} />
							))}
						</Box>
					</VStack>

					<Button
						fontWeight='bold'
						variant='link'
						color='gray.400'
						onClick={() => setSowSupportTexts(!showSupportTexts)}
					>
						{buttonText}
					</Button>
					
				</Box>
				
				<Flex flexDirection={'column'}>
					<PointBar />
					<ConclusionBar />
					{hasAnswers && (
						<VStack alignItems='flex-start' px={isMobile ? 3 : 9} pb={15} pt={4}>
							<Text mb={2} color='gray.300' fontSize='xs'>
								{t('evaluationPoint:essay.essays')}
							</Text>
							{essayPointResponses.map((essay, index) => (
								<EssayComponent
									content={essay}
									key={essay.id}
									pointId={pointId}
									isLastOne={essayPointResponses.length - 1 === index}
								/>
							))}
						</VStack>
					)}

					{!hasAnswers &&(
						<Flex
						mt={isMobile ? 24 : 12}
						w='full'
						alignItems='center'
						flexDirection='column'
						justifyContent='center'
						textAlign={'center'}
						mb={isMobile ? 16 : 0}
						>
							<Text fontWeight='600' color='gray.400'>
								{!hasAnswers &&  isClosingDatePassed || isClosedPoint
								? t('evaluationPoint:settings.evaluationConclusion.hasClosiedConclusion')
								: t('evaluationPoint:settings.evaluationConclusion.hasOpenedConclusion')}
							</Text>
						</Flex>
					)}
				</Flex>
				

				<CreateAnswerButton
					topic={pointContent.theme}
					pointId={pointContent.id}
					journeyId={journeyId}
				/>
			</ModalBody>
		</ModalContent>
	)
}
