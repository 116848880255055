import { fetch } from 'config'
import { ConclusionPointParams } from '../types/index'


export async function updateConclusionPoint({
	closingDate,
    endTimeMode,
	pointId
}: ConclusionPointParams) {
	const action = !endTimeMode ? 'disable' : 'enable'

	return await fetch<ConclusionPointParams>({
		url: `/projects/v1/essay-point/${pointId}/end-time-mode/${action}`,
		method: 'PATCH',
	})
}
