import { Trans, useTranslation } from 'react-i18next'
import { BiInfoCircle } from 'react-icons/bi'
import { Flex, Icon, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import { useEvaluationStore } from 'modules/map/store/evaluation-store'
import { useState, useEffect } from 'react'

export const ConclusionBar = () => {
  const { t, i18n: { language } } = useTranslation()
  const { closingDate, status, endTimeMode } = useEvaluationStore()
  const isClosedPassed = status === 'CLOSED'
  const [isClosed, setIsClosed] = useState(false)

  const isString = closingDate ? closingDate.toString() : ''
  const formattedDate = closingDate
    ? new Date(closingDate.includes('Z') ? closingDate : `${isString}Z`)
    : null

  useEffect(() => {
    if (!endTimeMode || !formattedDate) {
      setIsClosed(false)
      return
    }

    const checkClosingDate = () => {
      const currentTime = new Date()
      const truncatedCurrentTime = new Date(currentTime.setSeconds(0, 0))

      if (formattedDate) {
        const closingTime = new Date(formattedDate)
        const truncatedClosingTime = new Date(closingTime.setSeconds(0, 0))

        if (truncatedCurrentTime > truncatedClosingTime) {
          setIsClosed(true)
        } else {
          setIsClosed(false)
        }
      }
    }

    checkClosingDate()

    const intervalId = setInterval(checkClosingDate, 4000)

    return () => clearInterval(intervalId)
  }, [formattedDate, endTimeMode])

  if (!endTimeMode || !formattedDate) {
    return <Flex display='none' />
  }

  if (formattedDate && !isClosed) {
    const getDayHour = {
      date: format(formattedDate, 'dd/MM/yyyy'),
      hour: format(formattedDate, 'HH'),
      minute: format(formattedDate, 'mm')
    }

    return (
      <Flex
        bg='blue.500'
        color='white'
        p={1}
        m={0}
        align='center'
        justify='center'
        textAlign='center'
        w='100%'
      >
        <Icon as={BiInfoCircle} fontSize='xl' mr={1} />
        <Trans
          i18nKey='evaluationPoint:settings.evaluationConclusion.hasClosingDate'
          values={getDayHour}
        />
      </Flex>
    )
  }
  if (isClosedPassed || isClosed) {
    return (
      <Flex
        bg='gray.400'
        color='white'
        py={1}
        align='center'
        justify='center'
        textAlign='center'
        p={1}
        w='100%'
      >
        <Icon as={BiInfoCircle} fontSize='xl' mr={1} />
        <Text fontSize='md'>
          {t('evaluationPoint:settings.evaluationConclusion.closedPoint')}
        </Text>
      </Flex>
    )
  }

  return <Flex display='none' />
}
