import { useTranslation } from 'react-i18next'

import { Alert, AlertDescription, AlertIcon, Box, Button, HStack, Input, Stack, Switch, Text, useBreakpointValue, useToast } from '@chakra-ui/react'
import { Control, Controller, useForm, UseFormWatch } from 'react-hook-form'
import { EssayContentConverted, EssayCreateResponse } from 'modules/map/modules/evaluation-point/types'
import { useEvaluationStore } from 'modules/map/store/evaluation-store'
import { useEndTimeModeMutation } from '../../hooks/use-end-time-mode-mutation'
import { boolean } from 'yup'
import { todayDate } from 'modules/map/modules/convergence-point/helpers'
import { EvaluationValidation } from '../../validators/endTimeModeValidation'
import { yupResolver } from '@hookform/resolvers/yup'
import useSocket from 'config/socket/useSocket'
import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { useClosingDateMutation } from '../../hooks/use-closing-date-mutation';

export const Individual = ({
	isAnonymous,
	isIndividual
}: {
	isAnonymous: boolean
	isIndividual: boolean
}) => {
	const { t } = useTranslation()
	
	return (
		<Box>
			<HStack fontWeight='medium' fontSize='sm'>
				<Switch
					isDisabled={isAnonymous}
					colorScheme='switch_scheme_color_checked'
					isChecked={isIndividual}
				/>
				<Text>{t('evaluationPoint:settings.individual.switch')}</Text>
			</HStack>
			<Text color='gray.300' fontSize='xs'>
				{t('evaluationPoint:settings.anonymous.description')}
			</Text>
		</Box>
	)
}

export const Anonymous = ({
	isAnonymous,
	isIndividual
}: {
	isAnonymous: boolean
	isIndividual: boolean
}) => {
	const { t } = useTranslation()
	return (
		<Box>
			<HStack fontWeight='medium' fontSize='sm'>
				<Switch
					isDisabled={isIndividual}
					colorScheme='switch_scheme_color_checked'
					isChecked={isAnonymous}
				/>
				<Text>{t('evaluationPoint:settings.anonymous.switch')}</Text>
			</HStack>
			<Text color='gray.300' fontSize='xs'>
				{t('evaluationPoint:settings.multipleResponse.description')}
			</Text>
		</Box>
	)
}

export const MultipleResponse = ({
	isMultipleResponse
}: {
	isMultipleResponse: boolean
}) => {
	const { t } = useTranslation()

	return (
		<Box>
			<HStack fontWeight='medium' fontSize='sm'>
				<Switch
					colorScheme='switch_scheme_color_checked'
					isChecked={isMultipleResponse}
				/>
				<Text>{t('evaluationPoint:settings.multipleResponse.switch')}</Text>
			</HStack>
			<Text color='gray.300' fontSize='xs'>
				{t('evaluationPoint:settings.multipleResponse.description')}
			</Text>
		</Box>
	)
}

export const UserEvaluation = ({
	isUserEvaluation
}: {
	isUserEvaluation: boolean
}) => {
	const { t } = useTranslation()

	return (
		<Box>
			<HStack fontWeight='medium' fontSize='sm'>
				<Switch
					colorScheme='switch_scheme_color_checked'
					isChecked={isUserEvaluation}
				/>
				<Text>{t('evaluationPoint:settings.useEvaluation.switch')}</Text>
			</HStack>
			<Text color='gray.300' fontSize='xs'>
				{t('evaluationPoint:settings.useEvaluation.description')}
			</Text>
		</Box>
	)
}

export const EndTimeMode = ({
	essayPoint
  }: {
	essayPoint: EssayCreateResponse
  }) => {
	const { t } = useTranslation()
	const { mutate: updateConclusionPoint, isLoading } = useEndTimeModeMutation()
	const { mutate: updateClosingDate } = useClosingDateMutation()
  
	const toast = useToast()
	const isMobile = useBreakpointValue({ base: true, md: false })
  
	const { handleSubmit, control, formState: { errors }, getValues, setValue, watch, reset } = useForm({
	  resolver: yupResolver(EvaluationValidation),
	  defaultValues: {
		date: '',
		hour: '',
	  },
	  mode: 'onChange'
	})
  
	const channel = '/point/essay-point/end-time-mode'
	const { emit } = useSocket(channel)
	const { setEndTimeMode, setClosingDate, endTimeMode } = useEvaluationStore()
  
	const isConclusion = () => {
	  if (endTimeMode === true) {
		updateConclusionPoint({
		  endTimeMode: false,
		  closingDate: '',
		  pointId: essayPoint.id
		}, {
		  onSuccess() {
			setEndTimeMode(false)
			setClosingDate('')
			reset({ date: '', hour: '' })
		  },
		  onError(error) {},
		})
	  } else {
		setEndTimeMode(true)
	  }
	}
  
	const initialClosingDate = essayPoint.closing_date
	  ? new Date(`${essayPoint.closing_date}Z`)
	  : null
  
	const initialDate = initialClosingDate
	  ? format(initialClosingDate, 'yyyy-MM-dd')
	  : ''
  
	const initialHour = initialClosingDate
	  ? format(initialClosingDate, 'HH:mm')
	  : ''
  
	const [initialFormValues, setInitialFormValues] = useState({ date: initialDate, hour: initialHour })
  
	useEffect(() => {
	  if (initialDate && initialHour) {
		setValue('date', initialDate)
		setValue('hour', initialHour)
		setInitialFormValues({ date: initialDate, hour: initialHour })
	  }
	}, [initialDate, initialHour, setValue])
  
	const watchDate = watch('date') || ''
	const watchHour = watch('hour') || ''
  
	const isModified = (watchDate !== initialFormValues.date || watchHour !== initialFormValues.hour) 
						&& (watchDate !== '' || watchHour !== '')
  
	const handleFormSubmit = () => {
	  const date = getValues('date')
	  const hour = getValues('hour')
  
	  if (date && hour) {
		const closingDate = new Date(`${date}T${hour}`).toISOString()
  
		updateConclusionPoint({
		  endTimeMode: true,
		  closingDate,
		  pointId: essayPoint.id
		}, {
		  onSuccess() {
			updateClosingDate({
				endTimeMode: true,
				closingDate,
				pointId: essayPoint.id
			})
			toast({
			  title: t('evaluationPoint:settings.evaluationConclusion.hasCreatedOpenedConclusion'),
			  description: t('evaluationPoint:settings.evaluationConclusion.sendToEmail'),
			  position: 'bottom',
			  status: 'success',
			  duration: 3000,
      		  isClosable: true
			})
			setClosingDate(closingDate)
			setEndTimeMode(endTimeMode)
			emit('EDIT', essayPoint.id)
  
			setValue('date', date)
			setValue('hour', hour)
			setInitialFormValues({ date, hour })
		  },
		  onError(error) {
			console.error('error sending data:', error)
		  }
		})

	  } else {
		console.error('error sending data')
	  }
	}
  
	const dateErrorMessage = errors.date?.message
	const hourErrorMessage = errors.hour?.message
  
	return (
	  <Box>
		<form onSubmit={handleSubmit(handleFormSubmit)}>
		  <HStack fontWeight='medium' fontSize='sm'>
			<Switch
			  colorScheme='switch_scheme_color_checked'
			  isChecked={endTimeMode}
			  name='end_time_mode'
			  onChange={isConclusion}
			/>
			<Text>{t('evaluationPoint:settings.evaluationConclusion.switch')}</Text>
		  </HStack>
  
		  <Text color='gray.300' fontSize='xs'>
			{t('evaluationPoint:settings.evaluationConclusion.description')}
		  </Text>
  
		  <HStack
			spacing={4}
			align='center'
			w='100%'
			justify={isMobile ? 'flex-start' : 'space-between'}
		  >
			<Stack direction='column' alignItems='center'>
			  <Controller
				name='date'
				control={control}
				render={({ field }) => (
				  <>
					<Input
					  {...field}
					  variant='filled'
					  type='date'
					  isDisabled={!endTimeMode}
					  isInvalid={!!dateErrorMessage}
					  min={todayDate}
					  w='auto'
					/>
					{dateErrorMessage && (
					  <Text color='red.500' fontSize='sm'>
						{dateErrorMessage}
					  </Text>
					)}
				  </>
				)}
			  />
			</Stack>
			<Stack direction='column' alignItems='center'>
			  <Controller
				name='hour'
				control={control}
				render={({ field }) => (
				  <>
					<Input
					  {...field}
					  variant='filled'
					  type='time'
					  pattern='[0-9]{2}:[0-9]{2}'
					  placeholder='17:30'
					  isDisabled={!endTimeMode}
					  isInvalid={!!hourErrorMessage}
					  w='auto'
					/>
					{hourErrorMessage && (
					  <Text color='red.500' fontSize='sm'>
						{hourErrorMessage}
					  </Text>
					)}
				  </>
				)}
			  />
			</Stack>
  
			{!isMobile && (
			  <Button
				colorScheme='pink'
				isLoading={isLoading}
				type='submit'
				isDisabled={!endTimeMode || !isModified}
			  >
				{t('evaluationPoint:settings.evaluationConclusion.save')}
			  </Button>
			)}
		  </HStack>
  
		  {isMobile && (
			<Stack spacing={4} align='flex-start' mt={4}>
			  <Button
				colorScheme='pink'
				isLoading={isLoading}
				type='submit'
				isDisabled={!endTimeMode || !isModified}
			  >
				{t('evaluationPoint:settings.evaluationConclusion.save')}
			  </Button>
			</Stack>
		  )}
		</form>
	  </Box>
	)
  }